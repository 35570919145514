import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Icofont from 'react-icofont';
import * as Scroll from 'react-scroll';
import Footer from './Footer';
import Header from './Header';
let ScrollLink = Scroll.Link;
let ScrollElement = Scroll.Element;
// let scroll = Scroll.animateScroll;

function Eula() {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 600) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 600) {
      setShowScroll(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);

    return function unMount() {
      window.removeEventListener('scroll', checkScrollTop, false);
    };
  });
  return (
    <Fragment>
      <div className='swiss'>
        <Header />
        <Fragment>
          <section className='privacy'>
            <div className='privacy-header'>
              <h1>End-User License Agreement (EULA)</h1>
            </div>
            <div className='privacy-info'>
              <div className='info-area'>
                <ScrollElement name='topContent'>
                  <Row>
                    <Col xl={12}>
                      <h1>End-User License Agreement (EULA) GETLOOP</h1>
                      <h6>Last updated August 22, 2021</h6>
                      <p>
                        This End-User License Agreement ("EULA") is a legal
                        agreement between you and <b>GETLOOP</b>. Our EULA was
                        created by{' '}
                        <a href='https://www.eulatemplate.com'>EULA Template</a>{' '}
                        for <b>GETLOOP</b>.
                      </p>
                      <p>
                        This EULA agreement governs your acquisition and use of
                        our <b>GETLOOP</b> software ("Software") directly from{' '}
                        <b>GETLOOP</b> or indirectly through a <b>GETLOOP</b>{' '}
                        authorized reseller or distributor (a "Reseller").
                      </p>
                      <p>
                        Please read this EULA agreement carefully before
                        completing the installation process and using the{' '}
                        <b>GETLOOP</b> software. It provides a license to use
                        the <b>GETLOOP</b> software and contains warranty
                        information and liability disclaimers.
                      </p>
                      <p>
                        If you register for a free trial of the <b>GETLOOP</b>{' '}
                        software, this EULA agreement will also govern that
                        trial. By clicking "accept" or installing and/or using
                        the <b>GETLOOP</b> software, you are confirming your
                        acceptance of the Software and agreeing to become bound
                        by the terms of this EULA agreement.
                      </p>
                      <p>
                        If you are entering into this EULA agreement on behalf
                        of a company or other legal entity, you represent that
                        you have the authority to bind such entity and its
                        affiliates to these terms and conditions. If you do not
                        have such authority or if you do not agree with the
                        terms and conditions of this EULA agreement, do not
                        install or use the Software, and you must not accept
                        this EULA agreement.
                      </p>
                      <p>
                        This EULA agreement shall apply only to the Software
                        supplied by <b>GETLOOP</b> herewith regardless of
                        whether other software is referred to or described
                        herein. The terms also apply to any <b>GETLOOP</b>{' '}
                        updates, supplements, Internet-based services, and
                        support services for the Software, unless other terms
                        accompany those items on delivery. If so, those terms
                        apply.
                      </p>
                    </Col>
                    <Col xl={12}>
                      <h2 className='mt-5 mb-4'>License Grant</h2>
                      <p>
                        <b>GETLOOP</b> hereby grants you a personal,
                        non-transferable, non-exclusive licence to use the{' '}
                        <b>GETLOOP</b> software on your devices in accordance
                        with the terms of this EULA agreement.
                      </p>
                      <p>
                        You are permitted to load the <b>GETLOOP</b> software
                        (for example a PC, laptop, mobile or tablet) under your
                        control. You are responsible for ensuring your device
                        meets the minimum requirements of the <b>GETLOOP</b>{' '}
                        software.
                      </p>

                      <p>
                        Please read this EULA agreement carefully before
                        completing the installation process and using the{' '}
                        <b>GETLOOP</b> software. It provides a license to use
                        the <b>GETLOOP</b> software and contains warranty
                        information and liability disclaimers.
                      </p>
                      <p>
                        If you register for a free trial of the <b>GETLOOP</b>{' '}
                        software, this EULA agreement will also govern that
                        trial. By clicking "accept" or installing and/or using
                        the <b>GETLOOP</b> software, you are confirming your
                        acceptance of the Software and agreeing to become bound
                        by the terms of this EULA agreement.
                      </p>
                      <p>
                        If you are entering into this EULA agreement on behalf
                        of a company or other legal entity, you represent that
                        you have the authority to bind such entity and its
                        affiliates to these terms and conditions. If you do not
                        have such authority or if you do not agree with the
                        terms and conditions of this EULA agreement, do not
                        install or use the Software, and you must not accept
                        this EULA agreement.
                      </p>
                      <p>
                        This EULA agreement shall apply only to the Software
                        supplied by <b>GETLOOP</b> herewith regardless of
                        whether other software is referred to or described
                        herein. The terms also apply to any <b>GETLOOP</b>{' '}
                        updates, supplements, Internet-based services, and
                        support services for the Software, unless other terms
                        accompany those items on delivery. If so, those terms
                        apply.
                      </p>
                      <p>You are not permitted to:</p>
                      <ul>
                        <li>
                          Edit, alter, modify, adapt, translate or otherwise
                          change the whole or any part of the Software nor
                          permit the whole or any part of the Software to be
                          combined with or become incorporated in any other
                          software, nor decompile, disassemble or reverse
                          engineer the Software or attempt to do any such things
                        </li>
                        <li>
                          Reproduce, copy, distribute, resell or otherwise use
                          the Software for any commercial purpose
                        </li>
                        <li>
                          Allow any third party to use the Software on behalf of
                          or for the benefit of any third party
                        </li>
                        <li>
                          Use the Software in any way which breaches any
                          applicable local, national or international law
                        </li>
                        <li>
                          use the Software for any purpose that <b>GETLOOP</b>{' '}
                          considers is a breach of this EULA agreement
                        </li>
                      </ul>
                    </Col>
                    <Col xl={12}>
                      <h2 className='mt-5 mb-4'>
                        Intellectual Property and Ownership
                      </h2>

                      <p>
                        <b>GETLOOP</b> shall at all times retain ownership of
                        the Software as originally downloaded by you and all
                        subsequent downloads of the Software by you. The
                        Software (and the copyright, and other intellectual
                        property rights of whatever nature in the Software,
                        including any modifications made thereto) are and shall
                        remain the property of <b>GETLOOP</b>.
                      </p>

                      <p>
                        <b>GETLOOP</b> reserves the right to grant licences to
                        use the Software to third parties.
                      </p>
                    </Col>
                    <Col xl={12}>
                      <h2 className='mt-5 mb-4'>Termination</h2>

                      <p>
                        This EULA agreement is effective from the date you first
                        use the Software and shall continue until terminated.
                        You may terminate it at any time upon written notice to{' '}
                        <b>GETLOOP</b>.
                      </p>

                      <p>
                        It will also terminate immediately if you fail to comply
                        with any term of this EULA agreement. Upon such
                        termination, the licenses granted by this EULA agreement
                        will immediately terminate and you agree to stop all
                        access and use of the Software. The provisions that by
                        their nature continue and survive will survive any
                        termination of this EULA agreement.
                      </p>
                    </Col>
                    <Col xl={12}>
                      <h2 className='mt-5 mb-4'>Governing Law</h2>

                      <p>
                        This EULA agreement, and any dispute arising out of or
                        in connection with this EULA agreement, shall be
                        governed by and construed in accordance with the laws of{' '}
                        <b>US</b>.
                      </p>
                    </Col>
                  </Row>
                </ScrollElement>
              </div>
            </div>
            <ScrollLink
              to='topContent'
              smooth='easeInOutQuart'
              duration={1800}
              offset={-100}>
              <div
                className={showScroll ? 'back-to-top active' : 'back-to-top'}>
                <Icofont icon='long-arrow-up' />
              </div>
            </ScrollLink>
          </section>
        </Fragment>
        <Footer />
      </div>
    </Fragment>
  );
}

Eula.propTypes = {};

export default Eula;
