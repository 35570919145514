import React from 'react';
// import PropTypes from 'prop-types';
// import Instagram from '../assets/images/instagram.png';
// import Facebook from '../assets/images/facebook.png';
// import Youtube from '../assets/images/youtube.png';
// import appStore from '../assets/images/app-store.png';
// import googlePlay from '../assets/images/google-play.png';
import loop_logo from '../assets/images/loop_logo.png';
import Icofont from 'react-icofont';

import {
  // Navbar,
  Row,
  Col,
  Container,
  // Nav,
  // Image,
  // Modal,
  // Button,
  // Form,
  // Spinner,
} from 'react-bootstrap';

function Footer(props) {
  return (
    <footer className='footer'>
      <Container fluid>
        <Row className='justify-content-between'>
          <Col xl={6} lg={12}>
            <h5>Contact</h5>
            <div className='row mb-4'>
              <div className='col-xl-12 d-flex flex-column flex-md-row'>
                <div className='contact-box'>
                  <h4>Office Address</h4>
                  <p>200 Spectrum, Center Drive, Suite 300, Irvine, CA 92618</p>
                </div>
                <div className='contact-box'>
                  <h4>Email</h4>
                  <p>info@getloop.us</p>
                </div>
                <div className='contact-box'>
                  <h4>Phone</h4>
                  <p>+1 (949) 391 1852</p>
                  <p>+1 (949) 214 4431</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={3} lg={12}>
            <div className='mb-4'>
              <h5>Follow Us</h5>
              <div className='social-links'>
                <a
                  href='https://www.instagram.com/'
                  target='_blank'
                  rel='noreferrer'>
                  <Icofont icon='instagram' size='2' />
                  {/* <img src={Instagram} alt='instagram' width='40' /> */}
                </a>
                <a
                  href='https://www.facebook.com/'
                  target='_blank'
                  rel='noreferrer'>
                  <Icofont icon='facebook' size='2' />
                </a>
                <a
                  href='https://www.youtube.com/'
                  target='_blank'
                  rel='noreferrer'>
                  <Icofont icon='youtube' size='2' />
                </a>
              </div>
            </div>
          </Col>
          <Col xl={12}>
            <h6 className='mt-5 mt-sm-0'>
              <img src={loop_logo} className='mr-1' width='80' alt='logo' />
              &#169; Copyright, {new Date().getFullYear()}
            </h6>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

// Footer.propTypes = {};

export default Footer;
