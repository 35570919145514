import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Icofont from 'react-icofont';
import * as Scroll from 'react-scroll';
import Header from './Header';
import Footer from './Footer';
let ScrollLink = Scroll.Link;
let ScrollElement = Scroll.Element;

function Privacy() {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 1000) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 1000) {
      setShowScroll(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);

    return function unMount() {
      window.removeEventListener('scroll', checkScrollTop, false);
    };
  });
  return (
    <Fragment>
      <div className='swiss'>
        <Header />
        <section className='privacy'>
          <div className='privacy-header'>
            <h1>Privacy Policy</h1>
          </div>
          <div className='privacy-info'>
            <div className='info-area'>
              <Row>
                <Col xl={12}>
                  <h1>PRIVACY POLICY</h1>
                  <h6>Last updated August 22, 2021</h6>
                  <p>
                    Thank you for choosing to be part of our community at
                    GETLOOP ("
                    <b>Company,</b>" "<b>we</b>," "<b>us</b>," or "<b>our</b>").
                    We are committed to protecting your personal information and
                    your right to privacy. If you have any questions or concerns
                    about this privacy notice or our practices with regard to
                    your personal information, please contact us at{' '}
                    <a href='mailto:privacy-policy@getloop.us'>
                      Privacy-Policy@getloop.us
                    </a>
                    .
                  </p>
                  <p>
                    This privacy notice describes how we might use your
                    information if you:
                  </p>
                  <ul>
                    <li>
                      Visit our website at{' '}
                      <a
                        href='https://www.getloop.us'
                        target='_blank'
                        rel='noreferrer'>
                        https://www.getloop.us
                      </a>
                    </li>
                    <li>Download and use our mobile application — GETLOOP</li>
                    <li>
                      Engage with us in other related ways ― including any
                      sales, marketing, or events
                    </li>
                  </ul>
                  <p>In this privacy notice, if we refer to:</p>
                  <ul>
                    <li>
                      "<b>Website,</b>" we are referring to any website of ours
                      that references or links to this policy
                    </li>
                    <li>
                      "<b>App,</b>" we are referring to any application of ours
                      that references or links to this policy, including any
                      listed above
                    </li>
                    <li>
                      "<b>Services,</b>" we are referring to our Website, App,
                      and other related services, including any sales,
                      marketing, or events
                    </li>
                  </ul>
                  <p>
                    The purpose of this privacy notice is to explain to you in
                    the clearest way possible what information we collect, how
                    we use it, and what rights you have in relation to it. If
                    there are any terms in this privacy notice that you do not
                    agree with, please discontinue use of our Services
                    immediately.
                  </p>
                  <p className='my-4'>
                    <b>
                      Please read this privacy notice carefully, as it will help
                      you understand what we do with the information that we
                      collect.
                    </b>
                  </p>
                  <ScrollElement name='tableContent'>
                    <h4 className='mt-5 mb-4'>
                      <b>TABLE OF CONTENTS</b>
                    </h4>
                  </ScrollElement>
                  <Row>
                    <Col xl={6}>
                      <ul className='table-contents'>
                        <li>
                          <ScrollLink
                            to='one'
                            smooth='easeInOutQuart'
                            duration={1800}
                            offset={-100}>
                            <span className='text-dark'>1.</span> WHAT
                            INFORMATION DO WE COLLECT?
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                            to='two'
                            smooth='easeInOutQuart'
                            duration={1800}
                            offset={-100}>
                            <span className='text-dark'>2.</span> HOW DO WE USE
                            YOUR INFORMATION?
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                            to='three'
                            smooth='easeInOutQuart'
                            duration={1800}
                            offset={-100}>
                            <span className='text-dark'>3.</span> WILL YOUR
                            INFORMATION BE SHARED WITH ANYONE?
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                            to='four'
                            smooth='easeInOutQuart'
                            duration={1800}
                            offset={-100}>
                            <span className='text-dark'>4.</span> DO WE USE
                            COOKIES AND OTHER TRACKING TECHNOLOGIES?
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                            to='five'
                            smooth='easeInOutQuart'
                            duration={1800}
                            offset={-100}>
                            <span className='text-dark'>5.</span> HOW LONG DO WE
                            KEEP YOUR INFORMATION?
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                            to='six'
                            smooth='easeInOutQuart'
                            duration={1800}
                            offset={-100}>
                            <span className='text-dark'>6.</span> HOW DO WE KEEP
                            YOUR INFORMATION SAFE?
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                            to='seven'
                            smooth='easeInOutQuart'
                            duration={1800}
                            offset={-100}>
                            <span className='text-dark'>7.</span> DO WE COLLECT
                            INFORMATION FROM MINORS?
                          </ScrollLink>
                        </li>
                      </ul>
                    </Col>
                    <Col xl={6}>
                      <ul className='table-contents'>
                        <li>
                          <ScrollLink
                            to='eight'
                            smooth='easeInOutQuart'
                            duration={1800}
                            offset={-100}>
                            <span className='text-dark'>8.</span> WHAT ARE YOUR
                            PRIVACY RIGHTS?
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                            to='nine'
                            smooth='easeInOutQuart'
                            duration={1800}
                            offset={-100}>
                            <span className='text-dark'>9.</span> CONTROLS FOR
                            DO-NOT-TRACK FEATURES
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                            to='ten'
                            smooth='easeInOutQuart'
                            duration={1800}
                            offset={-100}>
                            <span className='text-dark'>10.</span> DO CALIFORNIA
                            RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                            to='eleven'
                            smooth='easeInOutQuart'
                            duration={1800}
                            offset={-100}>
                            <span className='text-dark'>11.</span> DO WE MAKE
                            UPDATES TO THIS NOTICE?
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                            to='twelve'
                            smooth='easeInOutQuart'
                            duration={1800}
                            offset={-100}>
                            <span className='text-dark'>12.</span> HOW CAN YOU
                            CONTACT US ABOUT THIS NOTICE?
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                            to='thirteen'
                            smooth='easeInOutQuart'
                            duration={1800}
                            offset={-100}>
                            <span className='text-dark'>13.</span> HOW CAN YOU
                            REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM
                            YOU?
                          </ScrollLink>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <ScrollElement name='one'>
                    <div className='table-content-area'>
                      <p>
                        <b>1.WHAT INFORMATION DO WE COLLECT?</b>
                      </p>
                      <p className='my-5'>
                        <b>Personal information you disclose to us</b>
                      </p>
                      <p className='font-italic'>
                        <span className='text-secondary mr-1'>
                          <b>In Short: </b>
                        </span>
                        We collect personal information that you provide to us.
                      </p>
                      <p>
                        We collect personal information that you voluntarily
                        provide to us when you register on the Services, express
                        an interest in obtaining information about us or our
                        products and Services, when you participate in
                        activities on the Services or otherwise when you contact
                        us.
                      </p>
                      <p>
                        The personal information that we collect depends on the
                        context of your interactions with us and the Services,
                        the choices you make and the products and features you
                        use. The personal information we collect may include the
                        following:
                      </p>
                      <p>
                        <b>Personal Information Provided by You.</b> We collect
                        names; email addresses; debit/credit card numbers;
                        billing addresses; and other similar information.
                      </p>
                      <p>
                        <b>Payment Data.</b> We may collect data necessary to
                        process your payment if you make purchases, such as your
                        payment instrument number (such as a credit card
                        number), and the security code associated with your
                        payment instrument. All payment data is stored by
                        Stripe. You may find their privacy notice link(s) here:{' '}
                        <a
                          href='https://stripe.com/privacy'
                          target='_blank'
                          rel='noreferrer'>
                          https://stripe.com/privacy
                        </a>
                        .
                      </p>
                      <p>
                        All personal information that you provide to us must be
                        true, complete and accurate, and you must notify us of
                        any changes to such personal information.
                      </p>
                      <p className='my-5'>
                        <b>Information automatically collected</b>
                      </p>
                      <p className='font-italic'>
                        <span className='text-secondary mr-1'>
                          <b>In Short: </b>
                        </span>
                        Some information — such as your Internet Protocol (IP)
                        address and/or browser and device characteristics — is
                        collected automatically when you visit our Services.
                      </p>
                      <p>
                        We automatically collect certain information when you
                        visit, use or navigate the Services. This information
                        does not reveal your specific identity (like your name
                        or contact information) but may include device and usage
                        information, such as your IP address, browser and device
                        characteristics, operating system, language preferences,
                        referring URLs, device name, country, location,
                        information about how and when you use our Services and
                        other technical information. This information is
                        primarily needed to maintain the security and operation
                        of our Services, and for our internal analytics and
                        reporting purposes.
                      </p>
                      <p>
                        Like many businesses, we also collect information
                        through cookies and similar technologies.
                      </p>
                      <p>The information we collect includes:</p>
                      <ul>
                        <li>
                          <span className='font-italic'>
                            Log and Usage Data.
                          </span>{' '}
                          Log and usage data is service-related, diagnostic,
                          usage and performance information our servers
                          automatically collect when you access or use our
                          Services and which we record in log files. Depending
                          on how you interact with us, this log data may include
                          your IP address, device information, browser type and
                          settings and information about your activity in the
                          Services (such as the date/time stamps associated with
                          your usage, pages and files viewed, searches and other
                          actions you take such as which features you use),
                          device event information (such as system activity,
                          error reports (sometimes called 'crash dumps') and
                          hardware settings).
                        </li>
                        <li>
                          <span className='font-italic'>Device Data.</span> We
                          collect device data such as information about your
                          computer, phone, tablet or other device you use to
                          access the Services. Depending on the device used,
                          this device data may include information such as your
                          IP address (or proxy server), device and application
                          identification numbers, location, browser type,
                          hardware model Internet service provider and/or mobile
                          carrier, operating system and system configuration
                          information.
                        </li>
                        <span className='font-italic'>Location Data.</span> We
                        collect location data such as information about your
                        device's location, which can be either precise or
                        imprecise. How much information we collect depends on
                        the type and settings of the device you use to access
                        the Services. For example, we may use GPS and other
                        technologies to collect geolocation data that tells us
                        your current location (based on your IP address). You
                        can opt out of allowing us to collect this information
                        either by refusing access to the information or by
                        disabling your Location setting on your device. Note
                        however, if you choose to opt out, you may not be able
                        to use certain aspects of the Services.
                      </ul>
                      <p className='my-5'>
                        <b>Information collected through our App</b>
                      </p>
                      <p className='font-italic'>
                        <span className='text-secondary mr-1'>
                          <b>In Short: </b>
                        </span>
                        We collect information regarding your push
                        notifications, when you use our App.
                      </p>
                      <p>
                        If you use our App, we also collect the following
                        information:
                      </p>
                      <ul>
                        <li>
                          Push Notifications. We may request to send you push
                          notifications regarding your account or certain
                          features of the App. If you wish to opt-out from
                          receiving these types of communications, you may turn
                          them off in your device's settings.
                        </li>
                      </ul>
                      <p>
                        This information is primarily needed to maintain the
                        security and operation of our App, for troubleshooting
                        and for our internal analytics and reporting purposes.
                      </p>
                    </div>
                  </ScrollElement>
                  <ScrollElement name='two'>
                    <div className='table-content-area'>
                      <p>
                        <b>2. HOW DO WE USE YOUR INFORMATION?</b>
                      </p>
                      <p className='font-italic'>
                        <span className='text-secondary mr-1'>
                          <b>In Short: </b>
                        </span>
                        We process your information for purposes based on
                        legitimate business interests, the fulfillment of our
                        contract with you, compliance with our legal
                        obligations, and/or your consent.
                      </p>
                      <p>
                        We use personal information collected via our Services
                        for a variety of business purposes described below. We
                        process your personal information for these purposes in
                        reliance on our legitimate business interests, in order
                        to enter into or perform a contract with you, with your
                        consent, and/or for compliance with our legal
                        obligations. We indicate the specific processing grounds
                        we rely on next to each purpose listed below.
                      </p>
                      <p>We use the information we collect or receive:</p>
                      <ul>
                        <li>
                          <b>
                            To facilitate account creation and logon process.
                          </b>{' '}
                          If you choose to link your account with us to a
                          third-party account (such as your Google or Facebook
                          account), we use the information you allowed us to
                          collect from those third parties to facilitate account
                          creation and logon process for the performance of the
                          contract.
                        </li>
                        <li>
                          <b>To post testimonials.</b> We post testimonials on
                          our Services that may contain personal information.
                          Prior to posting a testimonial, we will obtain your
                          consent to use your name and the content of the
                          testimonial. If you wish to update, or delete your
                          testimonial, please contact us at{' '}
                          <a href='mailto:privacy-policy@getloop.us'>
                            Privacy-Policy@getloop.us
                          </a>{' '}
                          and be sure to include your name, testimonial
                          location, and contact information.
                        </li>
                        <li>
                          <b>Request feedback.</b> We may use your information
                          to request feedback and to contact you about your use
                          of our Services.
                        </li>
                        <li>
                          <b>To enable user-to-user communications.</b> We may
                          use your information in order to enable user-to-user
                          communications with each user's consent.
                        </li>
                        <li>
                          <b>To manage user accounts.</b> We may use your
                          information for the purposes of managing our account
                          and keeping it in working order.
                        </li>
                        <li>
                          <b>Fulfill and manage your orders.</b> We may use your
                          information to fulfill and manage your orders,
                          payments, returns, and exchanges made through the
                          Services.
                        </li>
                        <li>
                          <b>Administer prize draws and competitions.</b> We may
                          use your information to administer prize draws and
                          competitions when you elect to participate in our
                          competitions.
                        </li>
                        <li>
                          <b>
                            To deliver and facilitate delivery of services to
                            the user.
                          </b>{' '}
                          We may use your information to provide you with the
                          requested service.
                        </li>
                        <li>
                          <b>
                            To respond to user inquiries/offer support to users.
                          </b>{' '}
                          We may use your information to respond to your
                          inquiries and solve any potential issues you might
                          have with the use of our Services.
                        </li>
                      </ul>
                    </div>
                  </ScrollElement>
                  <ScrollElement name='three'>
                    <div className='table-content-area'>
                      <p>
                        <b>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</b>
                      </p>
                      <p className='font-italic'>
                        <span className='text-secondary mr-1'>
                          <b>In Short: </b>
                        </span>
                        We only share information with your consent, to comply
                        with laws, to provide you with services, to protect your
                        rights, or to fulfill business obligations.
                      </p>
                      <p>
                        We may process or share your data that we hold based on
                        the following legal basis:
                      </p>
                      <ul>
                        <li>
                          <b>Consent:</b> We may process your data if you have
                          given us specific consent to use your personal
                          information for a specific purpose.
                        </li>
                        <li>
                          <b>Legitimate Interests:</b> We may process your data
                          when it is reasonably necessary to achieve our
                          legitimate business interests.
                        </li>
                        <li>
                          <b>Performance of a Contract:</b> Where we have
                          entered into a contract with you, we may process your
                          personal information to fulfill the terms of our
                          contract.
                        </li>
                        <li>
                          <b>Legal Obligations:</b> We may disclose your
                          information where we are legally required to do so in
                          order to comply with applicable law, governmental
                          requests, a judicial proceeding, court order, or legal
                          process, such as in response to a court order or a
                          subpoena (including in response to public authorities
                          to meet national security or law enforcement
                          requirements).
                        </li>
                        <li>
                          <b>Vital Interests:</b> We may disclose your
                          information where we believe it is necessary to
                          investigate, prevent, or take action regarding
                          potential violations of our policies, suspected fraud,
                          situations involving potential threats to the safety
                          of any person and illegal activities, or as evidence
                          in litigation in which we are involved.
                        </li>
                      </ul>
                      <p>
                        More specifically, we may need to process your data or
                        share your personal information in the following
                        situations:
                      </p>
                      <ul>
                        <li>
                          <b>Business Transfers.</b> We may share or transfer
                          your information in connection with, or during
                          negotiations of, any merger, sale of company assets,
                          financing, or acquisition of all or a portion of our
                          business to another company.
                        </li>
                      </ul>
                    </div>
                  </ScrollElement>
                  <ScrollElement name='four'>
                    <div className='table-content-area'>
                      <p>
                        <b>
                          4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                        </b>
                      </p>
                      <p className='font-italic'>
                        <span className='text-secondary mr-1'>
                          <b>In Short: </b>
                        </span>
                        We may use cookies and other tracking technologies to
                        collect and store your information.
                      </p>
                      <p>
                        We may use cookies and similar tracking technologies
                        (like web beacons and pixels) to access or store
                        information. Specific information about how we use such
                        technologies and how you can refuse certain cookies is
                        set out in our Cookie Notice.
                      </p>
                    </div>
                  </ScrollElement>
                  <ScrollElement name='five'>
                    <div className='table-content-area'>
                      <p>
                        <b>5. HOW LONG DO WE KEEP YOUR INFORMATION?</b>
                      </p>
                      <p className='font-italic'>
                        <span className='text-secondary mr-1'>
                          <b>In Short: </b>
                        </span>
                        We keep your information for as long as necessary to
                        fulfill the purposes outlined in this privacy notice
                        unless otherwise required by law.
                      </p>
                      <p>
                        We will only keep your personal information for as long
                        as it is necessary for the purposes set out in this
                        privacy notice, unless a longer retention period is
                        required or permitted by law (such as tax, accounting or
                        other legal requirements). No purpose in this notice
                        will require us keeping your personal information for
                        longer than the period of time in which users have an
                        account with us.
                      </p>
                      <p>
                        When we have no ongoing legitimate business need to
                        process your personal information, we will either delete
                        or anonymize such information, or, if this is not
                        possible (for example, because your personal information
                        has been stored in backup archives), then we will
                        securely store your personal information and isolate it
                        from any further processing until deletion is possible.
                      </p>
                    </div>
                  </ScrollElement>
                  <ScrollElement name='six'>
                    <div className='table-content-area'>
                      <p>
                        <b>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</b>
                      </p>
                      <p className='font-italic'>
                        <span className='text-secondary mr-1'>
                          <b>In Short: </b>
                        </span>
                        We aim to protect your personal information through a
                        system of organizational and technical security
                        measures.
                      </p>
                      <p>
                        We have implemented appropriate technical and
                        organizational security measures designed to protect the
                        security of any personal information we process.
                        However, despite our safeguards and efforts to secure
                        your information, no electronic transmission over the
                        Internet or information storage technology can be
                        guaranteed to be 100% secure, so we cannot promise or
                        guarantee that hackers, cybercriminals, or other
                        unauthorized third parties will not be able to defeat
                        our security, and improperly collect, access, steal, or
                        modify your information. Although we will do our best to
                        protect your personal information, transmission of
                        personal information to and from our Services is at your
                        own risk. You should only access the Services within a
                        secure environment.
                      </p>
                    </div>
                  </ScrollElement>
                  <ScrollElement name='seven'>
                    <div className='table-content-area'>
                      <p>
                        <b>7. DO WE COLLECT INFORMATION FROM MINORS?</b>
                      </p>
                      <p className='font-italic'>
                        <span className='text-secondary mr-1'>
                          <b>In Short: </b>
                        </span>
                        We do not knowingly collect data from or market to
                        children under 18 years of age.
                      </p>
                      <p>
                        We do not knowingly solicit data from or market to
                        children under 18 years of age. By using the Services,
                        you represent that you are at least 18 or that you are
                        the parent or guardian of such a minor and consent to
                        such minor dependent’s use of the Services. If we learn
                        that personal information from users less than 18 years
                        of age has been collected, we will deactivate the
                        account and take reasonable measures to promptly delete
                        such data from our records. If you become aware of any
                        data we may have collected from children under age 18,
                        please contact us at{' '}
                        <a
                          href='mailto:privacy-policy@getloop.us'
                          target='_blank'
                          rel='noreferrer'>
                          Privacy-Policy@getloop.us
                        </a>
                        .
                      </p>
                    </div>
                  </ScrollElement>
                  <ScrollElement name='eight'>
                    <div className='table-content-area'>
                      <p>
                        <b>8. WHAT ARE YOUR PRIVACY RIGHTS?</b>
                      </p>
                      <p className='font-italic'>
                        <span className='text-secondary mr-1'>
                          <b>In Short: </b>
                        </span>
                        In some regions, such as the European Economic Area
                        (EEA) and United Kingdom (UK), you have rights that
                        allow you greater access to and control over your
                        personal information. You may review, change, or
                        terminate your account at any time.
                      </p>
                      <p>
                        In some regions (like the EEA and UK), you have certain
                        rights under applicable data protection laws. These may
                        include the right (i) to request access and obtain a
                        copy of your personal information, (ii) to request
                        rectification or erasure; (iii) to restrict the
                        processing of your personal information; and (iv) if
                        applicable, to data portability. In certain
                        circumstances, you may also have the right to object to
                        the processing of your personal information. To make
                        such a request, please use the contact details provided
                        below. We will consider and act upon any request in
                        accordance with applicable data protection laws.
                      </p>
                      <p>
                        If we are relying on your consent to process your
                        personal information, you have the right to withdraw
                        your consent at any time. Please note however that this
                        will not affect the lawfulness of the processing before
                        its withdrawal, nor will it affect the processing of
                        your personal information conducted in reliance on
                        lawful processing grounds other than consent.
                      </p>
                      <p>
                        If you are a resident in the EEA or UK and you believe
                        we are unlawfully processing your personal information,
                        you also have the right to complain to your local data
                        protection supervisory authority. You can find their
                        contact details here:{' '}
                        <a
                          className='text-break'
                          href='https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'
                          target='_blank'
                          rel='noreferrer'>
                          https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                        </a>
                        .
                      </p>
                      <p>
                        If you are a resident in Switzerland, the contact
                        details for the data protection authorities are
                        available here:{' '}
                        <a
                          className='text-break'
                          href='https://www.edoeb.admin.ch/edoeb/en/home.html'
                          target='_blank'
                          rel='noreferrer'>
                          https://www.edoeb.admin.ch/edoeb/en/home.html
                        </a>
                        .
                      </p>
                      <p>
                        If you have questions or comments about your privacy
                        rights, you may email us at{' '}
                        <a href='mailto:privacy-policy@getloop.us'>
                          Privacy-Policy@getloop.us
                        </a>
                        .
                      </p>
                      <p className='my-5'>
                        <b>Account Information</b>
                      </p>
                      <p>
                        If you would at any time like to review or change the
                        information in your account or terminate your account,
                        you can:
                      </p>
                      <ul>
                        <li>
                          Log in to your account settings and update your user
                          account.
                        </li>
                      </ul>
                      <p>
                        Upon your request to terminate your account, we will
                        deactivate or delete your account and information from
                        our active databases. However, we may retain some
                        information in our files to prevent fraud, troubleshoot
                        problems, assist with any investigations, enforce our
                        Terms of Use and/or comply with applicable legal
                        requirements.
                      </p>
                      <p>
                        <b>
                          <u>Cookies and similar technologies:</u>
                        </b>{' '}
                        Most Web browsers are set to accept cookies by default.
                        If you prefer, you can usually choose to set your
                        browser to remove cookies and to reject cookies. If you
                        choose to remove cookies or reject cookies, this could
                        affect certain features or services of our Services. To
                        opt-out of interest-based advertising by advertisers on
                        our Services visit{' '}
                        <a
                          href='http://www.aboutads.info/choices/'
                          target='_blank'
                          rel='noreferrer'>
                          http://www.aboutads.info/choices/
                        </a>
                        .
                      </p>
                      <p>
                        <b>
                          <u> Opting out of email marketing:</u>
                        </b>{' '}
                        You can unsubscribe from our marketing email list at any
                        time by clicking on the unsubscribe link in the emails
                        that we send or by contacting us using the details
                        provided below. You will then be removed from the
                        marketing email list — however, we may still communicate
                        with you, for example to send you service-related emails
                        that are necessary for the administration and use of
                        your account, to respond to service requests, or for
                        other non-marketing purposes. To otherwise opt-out, you
                        may:
                      </p>
                      <ul>
                        <li>
                          Access your account settings and update your
                          preferences.
                        </li>
                      </ul>
                    </div>
                  </ScrollElement>
                  <ScrollElement name='nine'>
                    <div className='table-content-area'>
                      <p>
                        <b>9. CONTROLS FOR DO-NOT-TRACK FEATURES</b>
                      </p>
                      <p>
                        Most web browsers and some mobile operating systems and
                        mobile applications include a Do-Not-Track ("DNT")
                        feature or setting you can activate to signal your
                        privacy preference not to have data about your online
                        browsing activities monitored and collected. At this
                        stage no uniform technology standard for recognizing and
                        implementing DNT signals has been finalized. As such, we
                        do not currently respond to DNT browser signals or any
                        other mechanism that automatically communicates your
                        choice not to be tracked online. If a standard for
                        online tracking is adopted that we must follow in the
                        future, we will inform you about that practice in a
                        revised version of this privacy notice.
                      </p>
                    </div>
                  </ScrollElement>
                  <ScrollElement name='ten'>
                    <div className='table-content-area'>
                      <p>
                        <b>
                          10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
                          RIGHTS?
                        </b>
                      </p>
                      <p className='font-italic'>
                        <span className='text-secondary mr-1'>
                          <b>In Short: </b>
                        </span>
                        Yes, if you are a resident of California, you are
                        granted specific rights regarding access to your
                        personal information.
                      </p>
                      <p>
                        California Civil Code Section 1798.83, also known as the
                        "Shine The Light" law, permits our users who are
                        California residents to request and obtain from us, once
                        a year and free of charge, information about categories
                        of personal information (if any) we disclosed to third
                        parties for direct marketing purposes and the names and
                        addresses of all third parties with which we shared
                        personal information in the immediately preceding
                        calendar year. If you are a California resident and
                        would like to make such a request, please submit your
                        request in writing to us using the contact information
                        provided below.
                      </p>
                      <p>
                        If you are under 18 years of age, reside in California,
                        and have a registered account with a Service, you have
                        the right to request removal of unwanted data that you
                        publicly post on the Services. To request removal of
                        such data, please contact us using the contact
                        information provided below, and include the email
                        address associated with your account and a statement
                        that you reside in California. We will make sure the
                        data is not publicly displayed on the Services, but
                        please be aware that the data may not be completely or
                        comprehensively removed from all our systems (e.g.
                        backups, etc.).
                      </p>
                      <p className='my-5'>
                        <b>CCPA Privacy Notice</b>
                      </p>
                      <p>
                        The California Code of Regulations defines a "resident"
                        as:
                      </p>
                      <p>
                        (1) every individual who is in the State of California
                        for other than a temporary or transitory purpose and{' '}
                        <br />
                        (2) every individual who is domiciled in the State of
                        California who is outside the State of California for a
                        temporary or transitory purpose
                      </p>
                      <p>
                        All other individuals are defined as "non-residents."
                      </p>
                      <p>
                        If this definition of "resident" applies to you, we must
                        adhere to certain rights and obligations regarding your
                        personal information.
                      </p>
                      <p>
                        <b>
                          What categories of personal information do we collect?
                        </b>
                      </p>
                      <p>
                        We have collected the following categories of personal
                        information in the past twelve (12) months:
                      </p>
                      <div className='table-responsive'>
                        <table className='table table-bordered mb-5'>
                          <thead>
                            <tr>
                              <th>Category</th>
                              <th>Examples</th>
                              <th>Collected</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>A. Identifiers</td>
                              <td>
                                Contact details, such as real name, alias,
                                postal address, telephone or mobile contact
                                number, unique personal identifier, online
                                identifier, Internet Protocol address, email
                                address and account name
                              </td>
                              <td>YES</td>
                            </tr>
                            <tr>
                              <td>
                                B. Personal information categories listed in the
                                California Customer Records statute
                              </td>
                              <td>
                                Name, contact information, education,
                                employment, employment history and financial
                                information
                              </td>
                              <td>YES</td>
                            </tr>
                            <tr>
                              <td>
                                C. Protected classification characteristics
                                under California or federal law
                              </td>
                              <td>Gender and date of birth</td>
                              <td>NO</td>
                            </tr>
                            <tr>
                              <td>D. Commercial information</td>
                              <td>
                                Transaction information, purchase history,
                                financial details and payment information
                              </td>
                              <td>NO</td>
                            </tr>
                            <tr>
                              <td>E. Biometric information</td>
                              <td>Fingerprints and voiceprints</td>
                              <td>NO</td>
                            </tr>
                            <tr>
                              <td>
                                F. Internet or other similar network activity
                              </td>
                              <td>
                                Browsing history, search history, online
                                behavior, interest data, and interactions with
                                our and other websites, applications, systems
                                and advertisements
                              </td>
                              <td>NO</td>
                            </tr>
                            <tr>
                              <td>G. Geolocation data</td>
                              <td>Device location</td>
                              <td>NO</td>
                            </tr>
                            <tr>
                              <td>
                                H. Audio, electronic, visual, thermal,
                                olfactory, or similar information
                              </td>
                              <td>
                                Images and audio, video or call recordings
                                created in connection with our business
                                activities
                              </td>
                              <td>NO</td>
                            </tr>
                            <tr>
                              <td>
                                I. Professional or employment-related
                                information
                              </td>
                              <td>
                                Business contact details in order to provide you
                                our services at a business level, job title as
                                well as work history and professional
                                qualifications if you apply for a job with us
                              </td>
                              <td>NO</td>
                            </tr>
                            <tr>
                              <td>J. Education Information</td>
                              <td>Student records and directory information</td>
                              <td>NO</td>
                            </tr>
                            <tr>
                              <td>
                                K. Inferences drawn from other personal
                                information
                              </td>
                              <td>
                                Inferences drawn from any of the collected
                                personal information listed above to create a
                                profile or summary about, for example, an
                                individual’s preferences and characteristics
                              </td>
                              <td>NO</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p>
                        We may also collect other personal information outside
                        of these categories instances where you interact with us
                        in-person, online, or by phone or mail in the context
                        of:
                      </p>
                      <ul>
                        <li>
                          Receiving help through our customer support channels;
                        </li>
                        <li>
                          Participation in customer surveys or contests; and
                        </li>
                        <li>
                          Facilitation in the delivery of our Services and to
                          respond to your inquiries.
                        </li>
                      </ul>
                      <p>
                        <b>
                          How do we use and share your personal information?
                        </b>
                      </p>
                      <p>
                        More information about our data collection and sharing
                        practices can be found in this privacy notice.
                      </p>
                      <p>
                        You may contact us by email at{' '}
                        <a href='mailto:privacy-policy@getloop.us'>
                          Privacy-Policy@getloop.us
                        </a>
                        , or by referring to the contact details at the bottom
                        of this document.
                      </p>
                      <p>
                        If you are using an authorized agent to exercise your
                        right to opt-out we may deny a request if the authorized
                        agent does not submit proof that they have been validly
                        authorized to act on your behalf.
                      </p>
                      <p>
                        <b>Will your information be shared with anyone else?</b>
                      </p>
                      <p>
                        We may disclose your personal information with our
                        service providers pursuant to a written contract between
                        us and each service provider. Each service provider is a
                        for-profit entity that processes the information on our
                        behalf.
                      </p>
                      <p>
                        We may use your personal information for our own
                        business purposes, such as for undertaking internal
                        research for technological development and
                        demonstration. This is not considered to be "selling" of
                        your personal data.
                      </p>
                      <p>
                        GETLOOP has not disclosed or sold any personal
                        information to third parties for a business or
                        commercial purpose in the preceding 12 months. GETLOOP
                        will not sell personal information in the future
                        belonging to website visitors, users and other
                        consumers.
                      </p>
                      <p>
                        <b>Your rights with respect to your personal data</b>
                      </p>
                      <p>
                        <u>
                          Right to request deletion of the data - Request to
                          delete
                        </u>
                      </p>
                      <p>
                        You can ask for the deletion of your personal
                        information. If you ask us to delete your personal
                        information, we will respect your request and delete
                        your personal information, subject to certain exceptions
                        provided by law, such as (but not limited to) the
                        exercise by another consumer of his or her right to free
                        speech, our compliance requirements resulting from a
                        legal obligation or any processing that may be required
                        to protect against illegal activities.
                      </p>
                      <p>
                        <u>Right to be informed - Request to know</u>
                      </p>
                      <p>
                        Depending on the circumstances, you have a right to
                        know:
                      </p>
                      <ul>
                        <li>
                          whether we collect and use your personal information;
                        </li>
                        <li>
                          the categories of personal information that we
                          collect;
                        </li>
                        <li>
                          the purposes for which the collected personal
                          information is used;
                        </li>
                        <li>
                          whether we sell your personal information to third
                          parties;
                        </li>
                        <li>
                          the categories of personal information that we sold or
                          disclosed for a business purpose;
                        </li>
                        <li>
                          the categories of third parties to whom the personal
                          information was sold or disclosed for a business
                          purpose; and
                        </li>
                        <li>
                          the business or commercial purpose for collecting or
                          selling personal information.
                        </li>
                      </ul>
                      <p>
                        In accordance with applicable law, we are not obligated
                        to provide or delete consumer information that is
                        de-identified in response to a consumer request or to
                        re-identify individual data to verify a consumer
                        request.
                      </p>
                      <p>
                        <u>
                          Right to Non-Discrimination for the Exercise of a
                          Consumer’s Privacy Rights
                        </u>
                      </p>
                      <p>
                        We will not discriminate against you if you exercise
                        your privacy rights.
                      </p>
                      <p>
                        <u>Verification process</u>
                      </p>
                      <p>
                        Upon receiving your request, we will need to verify your
                        identity to determine you are the same person about whom
                        we have the information in our system. These
                        verification efforts require us to ask you to provide
                        information so that we can match it with information you
                        have previously provided us. For instance, depending on
                        the type of request you submit, we may ask you to
                        provide certain information so that we can match the
                        information you provide with the information we already
                        have on file, or we may contact you through a
                        communication method (e.g. phone or email) that you have
                        previously provided to us. We may also use other
                        verification methods as the circumstances dictate.
                      </p>
                      <p>
                        We will only use personal information provided in your
                        request to verify your identity or authority to make the
                        request. To the extent possible, we will avoid
                        requesting additional information from you for the
                        purposes of verification. If, however, we cannot verify
                        your identity from the information already maintained by
                        us, we may request that you provide additional
                        information for the purposes of verifying your identity,
                        and for security or fraud-prevention purposes. We will
                        delete such additionally provided information as soon as
                        we finish verifying you.
                      </p>
                      <p>
                        <u>Other privacy rights</u>
                      </p>
                      <ul>
                        <li>
                          you may object to the processing of your personal data
                        </li>
                        <li>
                          you may request correction of your personal data if it
                          is incorrect or no longer relevant, or ask to restrict
                          the processing of the data
                        </li>
                        <li>
                          you can designate an authorized agent to make a
                          request under the CCPA on your behalf. We may deny a
                          request from an authorized agent that does not submit
                          proof that they have been validly authorized to act on
                          your behalf in accordance with the CCPA.
                        </li>
                        <li>
                          you may request to opt-out from future selling of your
                          personal information to third parties. Upon receiving
                          a request to opt-out, we will act upon the request as
                          soon as feasibly possible, but no later than 15 days
                          from the date of the request submission.
                        </li>
                      </ul>
                      <p>
                        To exercise these rights, you can contact us by email at{' '}
                        <a href='mailto:privacy-policy@getloop.us'>
                          Privacy-Policy@getloop.us
                        </a>
                        , or by referring to the contact details at the bottom
                        of this document. If you have a complaint about how we
                        handle your data, we would like to hear from you.
                      </p>
                    </div>
                  </ScrollElement>
                  <ScrollElement name='eleven'>
                    <div className='table-content-area'>
                      <p>
                        <b>11. DO WE MAKE UPDATES TO THIS NOTICE? </b>
                      </p>
                      <p className='font-italic'>
                        <span className='text-secondary mr-1'>
                          <b>In Short: </b>
                        </span>
                        Yes, we will update this notice as necessary to stay
                        compliant with relevant laws.
                      </p>
                      <p>
                        We may update this privacy notice from time to time. The
                        updated version will be indicated by an updated
                        "Revised" date and the updated version will be effective
                        as soon as it is accessible. If we make material changes
                        to this privacy notice, we may notify you either by
                        prominently posting a notice of such changes or by
                        directly sending you a notification. We encourage you to
                        review this privacy notice frequently to be informed of
                        how we are protecting your information.
                      </p>
                    </div>
                  </ScrollElement>
                  <ScrollElement name='twelve'>
                    <div className='table-content-area'>
                      <p>
                        <b>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </b>
                      </p>
                      <p>
                        If you have questions or comments about this notice, you
                        may contact our Data Protection Officer (DPO), Office of
                        the CIO, by email at{' '}
                        <a href='mailto:privacy-policy@getloop.us'>
                          Privacy-Policy@getloop.us
                        </a>
                        , or by post to:
                      </p>
                      <p>GETLOOP</p>
                      <p>200 SPECTRUM, CENTER DRIVE,</p>
                      <p>SUITE 300, IRVINE, CA 92618</p>
                    </div>
                  </ScrollElement>
                </Col>
              </Row>
            </div>
          </div>
          <ScrollLink
            to='tableContent'
            smooth='easeInOutQuart'
            duration={1800}
            offset={-100}>
            <div className={showScroll ? 'back-to-top active' : 'back-to-top'}>
              <Icofont icon='long-arrow-up' />
            </div>
          </ScrollLink>
        </section>
        <Footer />
      </div>
    </Fragment>
  );
}

Privacy.propTypes = {};

export default Privacy;
