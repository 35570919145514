import React, { Fragment } from 'react';

import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import LandingPage from './components/LandingPage';
import Eula from './components/Eula';
import Privacy from './components/Privacy';

function App(props) {
  return (
    <Router>
      <Fragment>
        <Routes>
          <Route path='/' element={<LandingPage />}></Route>
          <Route path='/eula' element={<Eula />}></Route>
          <Route path='/privacy' element={<Privacy />}></Route>
        </Routes>
      </Fragment>
    </Router>
  );
}

export default App;
