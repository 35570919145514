import React from 'react';
// import PropTypes from 'prop-types';
import {
  Navbar,
  // Row,
  // Col,
  // Container,
  Nav,
  // Image,
  // Modal,
  // Button,
  // Form,
  // Spinner,
} from 'react-bootstrap';
import loop_logo from '../assets/images/loop_logo.png';
// import loop_logo_blue from '../assets/images/loop_logo_blue.png';
import User from '../assets/images/user.svg';

import { Link } from 'react-router-dom';

function Header(props) {
  return (
    <div className='swiss-navbar'>
      <Navbar
        collapseOnSelect
        expand='xl'
        // fixed='top'
        variant='light'
        style={{
          backgroundColor: 'transparent',
          zIndex: 999,
        }}
        className='justify-content-between navbarFixed bg-navbar shadow-lg'>
        {/* <LinkContainer to='/home'> */}
        <Navbar.Brand>
          <Link to='/'>
            <img src={loop_logo} alt='logo' className='img-fluid' width='100' />
          </Link>
        </Navbar.Brand>
        {/* </LinkContainer> */}
        {/* <Navbar.Toggle aria-controls='responsive-navbar-nav' /> */}
        {/* <Navbar.Collapse id='responsive-navbar-nav' className=''> */}
        <Nav.Link
          // className='nav-link'
          href='https://user.getloop.us'
          target='_blank'>
          Log In / Register{' '}
          <img
            src={User}
            alt='login'
            className='img-fluid ml-3 pb-2'
            width='20'
          />
        </Nav.Link>
        {/* <Nav>
              
            </Nav> */}
        {/* </Navbar.Collapse> */}
      </Navbar>
    </div>
  );
}

// Header.propTypes = {};

export default Header;
