import React, { Fragment, useState } from 'react';
import {
  // Navbar,
  Row,
  Col,
  Container,
  // Nav,
  Image,
  Modal,
  Button,
  Form,
  Spinner,
} from 'react-bootstrap';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import Header from './Header';
import Footer from './Footer';

import loop_logo from '../assets/images/loop_logo.png';
// import loop_logo_blue from '../assets/images/loop_logo_blue.png';
// import User from '../assets/images/user.svg';
// import Instagram from '../assets/images/instagram.png';
// import Facebook from '../assets/images/facebook.png';
// import Youtube from '../assets/images/youtube.png';
import appStore from '../assets/images/app-store.png';
import googlePlay from '../assets/images/google-play.png';

import ScoobyDoo from '../assets/images/ScoobyDoo.jpg';
// import AI from '../assets/images/AI.jpg';
import squidgame from '../assets/images/squidgame.jpg';
// import tomandjerry from '../assets/images/tomandjerry.jpg';
import ashes from '../assets/images/ashes.jpg';
// import chelsea from '../assets/images/chelsea.jpg';
import footballworldcup2018 from '../assets/images/footballworldcup2018.jpg';
// import couragethecowardlydog from '../assets/images/couragethecowardlydog.jpg';
// import dexter from '../assets/images/dexter.jpeg';
import MoneyHeist from '../assets/images/MoneyHeist.jpg';
import aliens from '../assets/images/aliens.jpg';
import ai from '../assets/images/ai.jpg';
import deeplearning from '../assets/images/deeplearning.jpg';
import joker from '../assets/images/joker.jpg';
import spidermannowayhomeposter from '../assets/images/spider-man-no-way-home-poster.jpg';
import thelionking from '../assets/images/the-lion-king.jpg';
import cybersecurity from '../assets/images/cybersecurity.jpg';
import snookerwc19 from '../assets/images/snookerwc19.jpg';
import Ninjago from '../assets/images/NinjagoSeason11.jpg';
import t202007 from '../assets/images/t202007.jpg';
import croods from '../assets/images/croods.jpg';
import cloudComputing from '../assets/images/cloudComputing.jpg';
import cliffordthebigreddogposter from '../assets/images/clifford-the-big-red-dog-poster.jpg';

import 'swiper/swiper-bundle.css';

import SwiperCore, { Parallax, Pagination, Navigation, Autoplay } from 'swiper';

SwiperCore.use([Parallax, Pagination, Navigation, Autoplay]);

let imageSection = [
  spidermannowayhomeposter,
  ai,
  aliens,
  joker,
  deeplearning,
  cliffordthebigreddogposter,
  squidgame,
  thelionking,
  cybersecurity,
  MoneyHeist,
  ScoobyDoo,
  snookerwc19,
];

let jsonDataBanner = [
  {
    Title: 'Artificial Intelligence        ',
    Description: `Artificial intelligence is the simulation of human intelligence processes by machines, especially computer systems. Specific applications of AI include expert systems, natural language processing, speech recognition and machine vision.`,
    Image: ai,
    id: 301,
  },
  {
    Title: 'The Croods',
    Description: `After their cave is destroyed, a caveman family must trek through an unfamiliar fantastical world with the help of an inventive boy.`,
    Image: croods,
    id: 202,
  },
  {
    Title: 'Joker',
    Description: `Arthur Fleck, a party clown, leads an impoverished life with his ailing mother. However, when society shuns him and brands him as a freak, he decides to embrace the life of crime and chaos.`,
    Image: joker,
    id: 102,
  },
  {
    Title: 'Scooby Doo!',
    Description: `Brainiac Velma, jock Fred, fashionista Daphne, hippie Shaggy, and Shaggy's highstrung, talking Great Dane, Scooby-Doo, climb into their green van, the Mystery Machine, and hit the road in search of weird phenomena to solve. And even when Scooby and the gang aren't actively searching for them, mysteries just have a way of falling into their laps. Ruh-roh!`,
    Image: ScoobyDoo,
    id: 201,
  },

  {
    Title: 'Cyber Security        ',
    Description: `It can serve as a reference manual for those working in the Cyber Security domain. The book takes a dip in history to talk about the very first computer virus, and at the same time, discusses in detail about the latest cyber threats.`,
    Image: cybersecurity,
    id: 301,
  },

  {
    Title: 'Squid Game Season 1',
    Description: `Hundreds of cash-strapped players accept a strange invitation to compete in children's games. Inside, a tempting prize awaits — with deadly high stakes. Starring:Lee Jung-jae, Park Hae-soo, Wi Ha-jun Creators:Hwang Dong-hyuk`,
    Image: squidgame,
    id: 202,
  },
  {
    Title: 'Deep Learning',
    Description: `An accessible introduction to the artificial intelligence technology that enables computer vision, speech recognition, machine translation, and driverless cars.
Deep learning is an artificial intelligence technology that enables computer vision, speech recognition in mobile phones, machine translation, AI games, driverless cars, and other applications. When we use consumer products from Google, Microsoft, Facebook, Apple, or Baidu, we are often interacting with a deep learning system. In this volume in the MIT Press Essential Knowledge series, computer scientist John Kelleher offers an accessible and concise but comprehensive introduction to the fundamental technology at the heart of the artificial intelligence revolution.`,
    Image: deeplearning,
    id: 101,
  },
  {
    Title: 'Ninjago: Secrets of the Forbidden Spinjitzu',
    Description: `Secrets of the Forbidden Spinjitzu is the eleventh season of the computer-animated Ninjago television series. The series was created by Michael Hegner and Tommy Andreasen. The season aired from 22 June 2019 to 1 February 2020, following the tenth season titled March of the Oni.`,
    Image: Ninjago,
    id: 202,
  },
  {
    Title: 'World Snooker Championship 2019',
    Description: `The 2019 World Snooker Championship was a professional snooker tournament that took place from 20 April to 6 May 2019 at the Crucible Theatre in Sheffield, England.`,
    Image: snookerwc19,
    id: 202,
  },
];

let jsonDataCategory = [
  {
    title: 'Education',
    data: [
      {
        Title: 'Artificial Intelligence        ',
        Description: `Artificial intelligence is the simulation of human intelligence processes by machines, especially computer systems. Specific applications of AI include expert systems, natural language processing, speech recognition and machine vision.`,
        Image: ai,
        id: 301,
      },
      {
        Title: 'Cyber Security        ',
        Description: `It can serve as a reference manual for those working in the Cyber Security domain. The book takes a dip in history to talk about the very first computer virus, and at the same time, discusses in detail about the latest cyber threats.`,
        Image: cybersecurity,
        id: 301,
      },
      {
        Title: 'Deep Learning',
        Description: `Deep learning is part of a broader family of machine learning methods based on artificial neural networks with representation learning. Learning can be supervised, semi-supervised or unsupervised.`,
        Image: deeplearning,
        id: 101,
      },
      {
        Title: 'Cloud Computing',
        Description: `Cloud computing is the on-demand availability of computer system resources, especially data storage and computing power, without direct active management by the user. Large clouds often have functions distributed over multiple locations, each location being a data center. `,
        Image: cloudComputing,
        id: 101,
      },
    ],
  },
  {
    title: 'Entertainment',
    data: [
      {
        Title: 'Spider Man No Way Home',
        Description: `With Spider-Man's identity now revealed, our friendly neighborhood web-slinger is unmasked and no longer able to separate his normal life as Peter Parker from the high stakes of being a superhero. When Peter asks for help from Doctor Strange, the stakes become even more dangerous, forcing him to discover what it truly means to be Spider-Man.`,
        Image: spidermannowayhomeposter,
        id: 202,
      },
      {
        Title: 'Joker',
        Description: `Arthur Fleck, a party clown, leads an impoverished life with his ailing mother. However, when society shuns him and brands him as a freak, he decides to embrace the life of crime and chaos.`,
        Image: joker,
        id: 102,
      },
      {
        Title: 'Ninjago: Secrets of the Forbidden Spinjitzu',
        Description: `Secrets of the Forbidden Spinjitzu is the eleventh season of the computer-animated Ninjago television series. The series was created by Michael Hegner and Tommy Andreasen. The season aired from 22 June 2019 to 1 February 2020, following the tenth season titled March of the Oni.`,
        Image: Ninjago,
        id: 202,
      },
      {
        Title: 'Squid Game Season 1',
        Description: `Hundreds of cash-strapped players accept a strange invitation to compete in children's games. Inside, a tempting prize awaits — with deadly high stakes. Starring:Lee Jung-jae, Park Hae-soo, Wi Ha-jun Creators:Hwang Dong-hyuk`,
        Image: squidgame,
        id: 202,
      },

      {
        Title: 'Scooby Doo!',
        Description: `Brainiac Velma, jock Fred, fashionista Daphne, hippie Shaggy, and Shaggy's highstrung, talking Great Dane, Scooby-Doo, climb into their green van, the Mystery Machine, and hit the road in search of weird phenomena to solve. And even when Scooby and the gang aren't actively searching for them, mysteries just have a way of falling into their laps. Ruh-roh!`,
        Image: ScoobyDoo,
        id: 201,
      },
      {
        Title: 'Money Heist',
        Description: `Money Heist (Spanish: La casa de papel, "The House of Paper") is a Spanish heist crime drama television series created by Álex Pina. The series traces two long-prepared heists led by the Professor (Álvaro Morte), one on the Royal Mint of Spain, and one on the Bank of Spain, told from the perspective of one of the robbers, Tokyo (Úrsula Corberó). The narrative is told in a real-time-like fashion and relies on flashbacks, time-jumps, hidden character motivations, and an unreliable narrator for complexity.`,
        Image: MoneyHeist,
        id: 202,
      },

      {
        Title: 'The Lion king',
        Description: `As a cub, Simba is forced to leave the Pride Lands after his father Mufasa is murdered by his wicked uncle, Scar. Years later, he returns as a young lion to reclaim his throne.`,
        Image: thelionking,
        id: 202,
      },

      {
        Title: 'The Croods',
        Description: `After their cave is destroyed, a caveman family must trek through an unfamiliar fantastical world with the help of an inventive boy.`,
        Image: croods,
        id: 202,
      },
    ],
  },
  {
    title: 'Sports',
    data: [
      {
        Title: 'T20 WorldCup 2007',
        Description: `The 2019 Ashes series was a series of Test cricket matches played between England and Australia for The Ashes in August and September 2019. The venues were Edgbaston, Lord's, Headingley, Old Trafford, and The Oval.`,
        Image: t202007,
        id: 101,
      },
      {
        Title: 'World Snooker Championship 2019',
        Description: `The 2019 World Snooker Championship was a professional snooker tournament that took place from 20 April to 6 May 2019 at the Crucible Theatre in Sheffield, England.`,
        Image: snookerwc19,
        id: 202,
      },
      {
        Title: 'Ashes 2019',
        Description: `The 2019 Ashes series was a series of Test cricket matches played between England and Australia for The Ashes in August and September 2019. The venues were Edgbaston, Lord's, Headingley, Old Trafford, and The Oval.`,
        Image: ashes,
        id: 101,
      },
      {
        Title: 'Football World Cup 2018',
        Description: `The 2018 FIFA World Cup was an international football tournament contested by men's national teams that took place between 14 June and 15 July 2018 in Russia. It was the 21st FIFA World Cup, a worldwide football tournament held once every four years. It was the eleventh time the championships had been held in Europe, and the first time they were held in Eastern Europe. At an estimated cost of over $14.2 billion, it was the most expensive World Cup to date.`,
        Image: footballworldcup2018,
        id: 102,
      },
    ],
  },
];

function LandingPage() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [messageResponse, setMessageResponse] = useState('');
  const openModal = (i, categoryTitle) => {
    let data = { ...i, categoryTitle };
    setModalData(data);
    setShowModal(true);
  };

  const sendContactForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log(data);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post(
        `https://getloop.us/loginAndRegister/api/support/contact/`,
        data,
        config
      );
      if (res) {
        // console.log('send contact form: ', res);
        setMessageResponse(res.data.description);
        setLoading(false);
        setTimeout(() => {
          setMessageResponse('');
        }, 10000);
        setData({
          first_name: '',
          last_name: '',
          email: '',
          subject: '',
          message: '',
        });
      }
      return true;
    } catch (error) {
      setLoading(false);

      // console.log('send contact form: ', error);
    }
  };

  return (
    <Fragment>
      <div className='swiss'>
        <Header />

        <section>
          <div className='customSwiper' style={{ marginTop: '-65px' }}>
            <div
              className=' text-white shadow d-flex'
              style={{ height: '60vh', minHeight: '550px' }}>
              <div slot='container-start' className='parallax-bg bg-Img'>
                <Swiper
                  style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                    height: '60vh',
                    minHeight: '550px',
                  }}
                  speed={600}
                  // parallax={true}
                  pagination={{
                    clickable: true,
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  navigation
                  loop={true}
                  className='mySwiper text-white'>
                  {jsonDataBanner.map((data, i) => (
                    <SwiperSlide
                      className='justify-content-center d-flex flex-column'
                      key={i}>
                      <Container>
                        <Row>
                          <Col
                            xl={6}
                            md={5}
                            sm={12}
                            className='d-none d-lg-block'>
                            <Image
                              src={data.Image}
                              rounded
                              className='thumbnailShadow'
                              data-swiper-parallax='-200'
                              width='50%'
                              alt={`Banner ${i}`}
                            />
                          </Col>
                          <Col
                            xl={6}
                            md={7}
                            sm={12}
                            className='justify-content-center d-flex flex-column'>
                            <div className='title' data-swiper-parallax='-300'>
                              <p className='textShadow'>{data.Title}</p>
                            </div>
                            <div className='text' data-swiper-parallax='-200'>
                              <p className='textShadow'>{data.Description}</p>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        <section
          className='stream px-0 py-5'
          // style={{ backgroundColor: '#ffffff1a' }}
        >
          <div className='' style={{ minHeight: '30vh' }}>
            <Container>
              <Row>
                {imageSection.map((i, index) => (
                  <Col xl={2} sm={2} xs={2} lg={2} className='p-0' key={index}>
                    <img src={i} alt={`${index}`} className='img-fluid w-100' />
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </section>

        <section className='px-0 py-5' style={{ backgroundColor: '#ffffff1a' }}>
          <Container fluid>
            <Row className='no-gutters'>
              <Col xl={12}>
                <h1 className='text-center'>Start Viewing Today</h1>
                <p className='text-center my-3'>
                  Over 100+ Hours of on-demand content with a variety of
                  relevant and interesting Categories.
                </p>
              </Col>
              <Col xl={12}>
                <Container>
                  {jsonDataCategory.map((data, i) => (
                    <Fragment key={i}>
                      <Row className='py-3'>
                        <Col xl={12}>
                          <h3 className='text-uppercase font-weight-bold'>
                            {data.title}
                          </h3>
                        </Col>
                        {data.data.map((i, index) => (
                          <Col
                            lg={3}
                            sm={3}
                            xs={3}
                            key={index}
                            className='px-1'
                            id='cardColumn'>
                            <img
                              src={i.Image}
                              className='img-fluid w-100 my-1'
                              id='imgHover'
                              alt={i.Title}
                              onClick={() => {
                                openModal(i, data.title);
                              }}
                            />
                          </Col>
                        ))}
                      </Row>
                      <hr />
                    </Fragment>
                  ))}
                </Container>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className='support py-5'
          // style={{ backgroundColor: '#007bff1a' }}
        >
          <Container>
            <Row>
              <Col xl={6} lg={12} className='bd-r'>
                <h1>
                  Getting Started with{' '}
                  <img
                    src={loop_logo}
                    className='mr-1'
                    width='120'
                    alt='Logo'
                  />
                </h1>
                <ul>
                  <li>Frequently Asked Questions</li>
                  <li>What's Included?</li>
                  <li>How Do I Sign Up?</li>
                </ul>
                <h5>Office Hours</h5>
                <p>
                  Ask questions to any questions, clarifications from the
                  content shared in our categories. Office Hours is hosted
                  through our Mobile App, Click to Join Us.
                </p>
                {/* <Button>Open App</Button> */}
                <a
                  href='https://apps.apple.com/us/app/get-loop/id1600518359'
                  target='_blank'
                  rel='noreferrer'>
                  <img
                    src={appStore}
                    width='200'
                    className='mx-1'
                    alt='App Store'
                  />
                </a>
                <a
                  href='https://play.google.com/store/apps/details?id=com.getloop'
                  target='_blank'
                  rel='noreferrer'>
                  <img
                    src={googlePlay}
                    width='200'
                    className='mx-1'
                    alt='Google Play'
                  />
                </a>
              </Col>
              <Col xl={6} lg={12}>
                <Form
                  onSubmit={(e) => sendContactForm(e)}
                  className='ml-0 ml-xl-3 text-left text-xl-center mt-5 mt-xl-0'>
                  <h3 className='mb-3'>How Can We Help You?</h3>
                  <Form.Row className='mb-3'>
                    <Col>
                      <Form.Control
                        placeholder='First name'
                        name='first_name'
                        onChange={(e) =>
                          setData({
                            ...data,
                            [e.target.name]: e.target.value,
                          })
                        }
                        required
                        value={data.first_name}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        placeholder='Last name'
                        name='last_name'
                        onChange={(e) =>
                          setData({
                            ...data,
                            [e.target.name]: e.target.value,
                          })
                        }
                        required
                        value={data.last_name}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row className='mb-3'>
                    <Col>
                      <Form.Control
                        placeholder='Email'
                        type='email'
                        name='email'
                        onChange={(e) =>
                          setData({
                            ...data,
                            [e.target.name]: e.target.value,
                          })
                        }
                        required
                        value={data.email}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row className='mb-3'>
                    <Col>
                      <Form.Control
                        placeholder='Subject'
                        name='subject'
                        onChange={(e) =>
                          setData({
                            ...data,
                            [e.target.name]: e.target.value,
                          })
                        }
                        required
                        value={data.subject}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row className='mb-3'>
                    <Col>
                      <Form.Control
                        as='textarea'
                        placeholder='Message'
                        rows={3}
                        name='message'
                        onChange={(e) =>
                          setData({
                            ...data,
                            [e.target.name]: e.target.value,
                          })
                        }
                        required
                        value={data.message}
                      />
                    </Col>
                  </Form.Row>
                  <p className='text-swiss text-center mt-3'>
                    {messageResponse}
                  </p>
                  <Button
                    type='submit'
                    variant='light'
                    // className='customBtn'
                    disabled={loading}>
                    {/* {isLoading ? ( */}
                    <span className='d-flex align-items-center'>
                      Submit
                      {loading ? (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : null}
                    </span>
                    {/* ) : ( */}
                    {/* Submit */}
                    {/* )} */}
                  </Button>
                  {/* {successMsg ? ( */}

                  {/* ) : null} */}
                </Form>
              </Col>
            </Row>
          </Container>
        </section>

        <section>
          <div style={{ width: '100%', height: '400px' }}>
            <iframe
              className='map'
              title='Google Map'
              width='100%'
              height='100%'
              frameBorder='0'
              scrolling='no'
              marginHeight='0'
              marginWidth='0'
              src='https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=200%20SPECTRUM,%20CENTER%20DRIVE,%20SUITE%20300,%20IRVINE,%20CA%2092618+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'></iframe>
          </div>
        </section>

        <Footer />
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size='lg'
        className='single-course-modal'
        variant='dark'
        backdrop='static'>
        <Modal.Header className='border-bottom' closeButton>
          <h2>{modalData?.categoryTitle}</h2>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={4} lg={4} md={12} className='text-center text-lg-left'>
              <img
                src={modalData?.Image}
                className='img-fluid w-100 shadow rounded-3'
                alt={modalData?.Title}
                style={{ height: '300px' }}
              />
            </Col>
            <Col xl={8} lg={8} md={12} className='mt-4 mt-lg-0'>
              <h2>{modalData?.Title}</h2>
              <h6>{modalData?.Description}</h6>
              <Row className='mt-5'>
                <Col
                  xl={6}
                  lg={6}
                  md={6}
                  // sm={12}
                  className='d-flex flex-column py-2'>
                  {/* <p>$9.99 | Month</p> */}
                  <a
                    href='https://user.getloop.us'
                    target='_blank'
                    rel='noreferrer'
                    className='btn btn-light shadow w-100'>
                    Watch Now
                  </a>
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  md={6}
                  // sm={12}
                  className='d-flex flex-column py-2'>
                  {/* <p>$9.99 | Month</p> */}
                  <a
                    href='https://user.getloop.us/#/register'
                    target='_blank'
                    rel='noreferrer'
                    className='btn btn-light shadow'>
                    Register
                  </a>
                </Col>
              </Row>
              {/* <div className='mt-3'>
                <small>
                  *Cancel at anytime with a 7 day money back guarantee
                </small>
              </div> */}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default LandingPage;
